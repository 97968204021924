


































































import 'vue-material-design-icons/styles.css'
import Ribbon from 'vue-ribbon'
import LeftIcon from 'vue-material-design-icons/ChevronLeftCircle'
import RightIcon from 'vue-material-design-icons/ChevronRightCircle'
import PlusIcon from 'vue-material-design-icons/PlusCircle'
import MinusIcon from 'vue-material-design-icons/MinusCircle'
import Flipbook from './Flipbook'

export default
  name: 'app'
  components: { Flipbook, LeftIcon, RightIcon, PlusIcon, MinusIcon, Ribbon }
  data: ->
    pages: [],
    pagesHiRes: [],
    hasMouse: true
    pageNum: null
  methods:
    onFlipLeftStart: (page) -> console.log 'flip-left-start', page
    onFlipLeftEnd: (page) ->
      console.log 'flip-left-end', page
      window.location.hash = '#' + page
    onFlipRightStart: (page) -> console.log 'flip-right-start', page
    onFlipRightEnd: (page) ->
      console.log 'flip-right-end', page
      window.location.hash = '#' + page
    onZoomStart: (zoom) -> console.log 'zoom-start', zoom
    onZoomEnd: (zoom) -> console.log 'zoom-end', zoom
    setPageFromHash: ->
      n = parseInt window.location.hash.slice(1), 10
      @pageNum = n if isFinite n
  mounted: ->
    window.addEventListener 'keydown', (ev) =>
      flipbook = @$refs.flipbook
      return unless flipbook
      flipbook.flipLeft() if ev.keyCode == 37 and flipbook.canFlipLeft
      flipbook.flipRight() if ev.keyCode == 39 and flipbook.canFlipRight

    # Simulate asynchronous pages initialization
    setTimeout (=>
      @pages = [
        null
        'images/b1.jpg'
        'images/b2.jpg'
        'images/b3.jpg'
        'images/b4.jpg'
        'images/b5.jpg'
        'images/b6.jpg'
        'images/b7.jpg'
        'images/b8.jpg'
        'images/b9.jpg'
        'images/b10.jpg'
        'images/b11.jpg'
        'images/b12.jpg'
        'images/b13.jpg'
        'images/b14.jpg'
        'images/b15.jpg'
        'images/b16.jpg'
        'images/b17.jpg'
        'images/b18.jpg'
        'images/b19.jpg'
        'images/b20.jpg'
        'images/b21.jpg'
        'images/b22.jpg'
        'images/b23.jpg'
        'images/b24.jpg'
        'images/b25.jpg'
        'images/b26.jpg'
        'images/b27.jpg'
        'images/b28.jpg'
        'images/b29.jpg'
        'images/b30.jpg'
        'images/b31.jpg'
        'images/b32.jpg'
        'images/b33.jpg'
        'images/b34.jpg'
        'images/b35.jpg'
        'images/b36.jpg'
        'images/b37.jpg'
        'images/b38.jpg'
        'images/b39.jpg'
        'images/b40.jpg'
        'images/b41.jpg'
        'images/b42.jpg'
        'images/b43.jpg'
        'images/b44.jpg'
        'images/b45.jpg'
        'images/b46.jpg'
        'images/b47.jpg'
        'images/b48.jpg'
        'images/b49.jpg'
        'images/b50.jpg'
        'images/b51.jpg'
        'images/b52.jpg'
        'images/b53.jpg'
        'images/b54.jpg'
        'images/b55.jpg'
        'images/b56.jpg'
        'images/b57.jpg'
        'images/b58.jpg'
        'images/b59.jpg'
        'images/b60.jpg'
        'images/b61.jpg'
        'images/b62.jpg'
        'images/b63.jpg'
        'images/b64.jpg'
        'images/b65.jpg'
        'images/b66.jpg'
        'images/b67.jpg'
        'images/b68.jpg'
        'images/b69.jpg'
        'images/b70.jpg'
        'images/b71.jpg'
        'images/b72.jpg'
        'images/b73.jpg'
        'images/b74.jpg'
        'images/b75.jpg'
        'images/b76.jpg'
        'images/b77.jpg'
        'images/b78.jpg'
        'images/b79.jpg'
        'images/b80.jpg'
        'images/b81.jpg'
        'images/b82.jpg'
        'images/b83.jpg'
        'images/b84.jpg'
        'images/b85.jpg'
        'images/b86.jpg'
        'images/b87.jpg'
        'images/b88.jpg'
        'images/b89.jpg'
        'images/b90.jpg'
        'images/b91.jpg'
        'images/b92.jpg'
        'images/b93.jpg'
        'images/b94.jpg'
        'images/b95.jpg'
        'images/b96.jpg'
        'images/b97.jpg'
        'images/b98.jpg'
        'images/b99.jpg'
        'images/b100.jpg'
        'images/b101.jpg'
        'images/b102.jpg'
        'images/b103.jpg'
        'images/b104.jpg'
        'images/b105.jpg'
        'images/b106.jpg'
        'images/b107.jpg'
        'images/b108.jpg'
        'images/b109.jpg'
        'images/b110.jpg'
        'images/b111.jpg'
        'images/b112.jpg'
        'images/b113.jpg'
        'images/b114.jpg'
        'images/b115.jpg'
        'images/b116.jpg'
        'images/b117.jpg'
        'images/b118.jpg'
        'images/b119.jpg'
        'images/b120.jpg'
        'images/b121.jpg'
        'images/b122.jpg'
        'images/b123.jpg'
        'images/b124.jpg'
        'images/b125.jpg'
        'images/b126.jpg'
        'images/b127.jpg'
        'images/b128.jpg'
        'images/b129.jpg'
        'images/b130.jpg'
        'images/b131.jpg'
        'images/b132.jpg'
        'images/b133.jpg'
        'images/b134.jpg'
        'images/b135.jpg'
        'images/b136.jpg'
        'images/b137.jpg'
        'images/b138.jpg'
        'images/b139.jpg'
        'images/b140.jpg'
        'images/b141.jpg'
        'images/b142.jpg'
        'images/b143.jpg'
        'images/b144.jpg'
        'images/b145.jpg'
        'images/b146.jpg'
        'images/b147.jpg'
        'images/b148.jpg'
        'images/b149.jpg'
        'images/b150.jpg'
        'images/b151.jpg'
        'images/b152.jpg'
      ]
      @pagesHiRes = [
        null
        'images/b1.jpg'
        'images/b2.jpg'
        'images/b3.jpg'
        'images/b4.jpg'
        'images/b5.jpg'
        'images/b6.jpg'
        'images/b7.jpg'
        'images/b8.jpg'
        'images/b9.jpg'
        'images/b10.jpg'
        'images/b11.jpg'
        'images/b12.jpg'
        'images/b13.jpg'
        'images/b14.jpg'
        'images/b15.jpg'
        'images/b16.jpg'
        'images/b17.jpg'
        'images/b18.jpg'
        'images/b19.jpg'
        'images/b20.jpg'
        'images/b21.jpg'
        'images/b22.jpg'
        'images/b23.jpg'
        'images/b24.jpg'
        'images/b25.jpg'
        'images/b26.jpg'
        'images/b27.jpg'
        'images/b28.jpg'
        'images/b29.jpg'
        'images/b30.jpg'
        'images/b31.jpg'
        'images/b32.jpg'
        'images/b33.jpg'
        'images/b34.jpg'
        'images/b35.jpg'
        'images/b36.jpg'
        'images/b37.jpg'
        'images/b38.jpg'
        'images/b39.jpg'
        'images/b40.jpg'
        'images/b41.jpg'
        'images/b42.jpg'
        'images/b43.jpg'
        'images/b44.jpg'
        'images/b45.jpg'
        'images/b46.jpg'
        'images/b47.jpg'
        'images/b48.jpg'
        'images/b49.jpg'
        'images/b50.jpg'
        'images/b51.jpg'
        'images/b52.jpg'
        'images/b53.jpg'
        'images/b54.jpg'
        'images/b55.jpg'
        'images/b56.jpg'
        'images/b57.jpg'
        'images/b58.jpg'
        'images/b59.jpg'
        'images/b60.jpg'
        'images/b61.jpg'
        'images/b62.jpg'
        'images/b63.jpg'
        'images/b64.jpg'
        'images/b65.jpg'
        'images/b66.jpg'
        'images/b67.jpg'
        'images/b68.jpg'
        'images/b69.jpg'
        'images/b70.jpg'
        'images/b71.jpg'
        'images/b72.jpg'
        'images/b73.jpg'
        'images/b74.jpg'
        'images/b75.jpg'
        'images/b76.jpg'
        'images/b77.jpg'
        'images/b78.jpg'
        'images/b79.jpg'
        'images/b80.jpg'
        'images/b81.jpg'
        'images/b82.jpg'
        'images/b83.jpg'
        'images/b84.jpg'
        'images/b85.jpg'
        'images/b86.jpg'
        'images/b87.jpg'
        'images/b88.jpg'
        'images/b89.jpg'
        'images/b90.jpg'
        'images/b91.jpg'
        'images/b92.jpg'
        'images/b93.jpg'
        'images/b94.jpg'
        'images/b95.jpg'
        'images/b96.jpg'
        'images/b97.jpg'
        'images/b98.jpg'
        'images/b99.jpg'
        'images/b100.jpg'
        'images/b101.jpg'
        'images/b102.jpg'
        'images/b103.jpg'
        'images/b104.jpg'
        'images/b105.jpg'
        'images/b106.jpg'
        'images/b107.jpg'
        'images/b108.jpg'
        'images/b109.jpg'
        'images/b110.jpg'
        'images/b111.jpg'
        'images/b112.jpg'
        'images/b113.jpg'
        'images/b114.jpg'
        'images/b115.jpg'
        'images/b116.jpg'
        'images/b117.jpg'
        'images/b118.jpg'
        'images/b119.jpg'
        'images/b120.jpg'
        'images/b121.jpg'
        'images/b122.jpg'
        'images/b123.jpg'
        'images/b124.jpg'
        'images/b125.jpg'
        'images/b126.jpg'
        'images/b127.jpg'
        'images/b128.jpg'
        'images/b129.jpg'
        'images/b130.jpg'
        'images/b131.jpg'
        'images/b132.jpg'
        'images/b133.jpg'
        'images/b134.jpg'
        'images/b135.jpg'
        'images/b136.jpg'
        'images/b137.jpg'
        'images/b138.jpg'
        'images/b139.jpg'
        'images/b140.jpg'
        'images/b141.jpg'
        'images/b142.jpg'
        'images/b143.jpg'
        'images/b144.jpg'
        'images/b145.jpg'
        'images/b146.jpg'
        'images/b147.jpg'
        'images/b148.jpg'
        'images/b149.jpg'
        'images/b150.jpg'
        'images/b151.jpg'
        'images/b152.jpg'
      ]
    ), 1

    window.addEventListener 'hashchange', @setPageFromHash
    @setPageFromHash()
